import React from "react";

import { Icon } from '@types';

const AnchorIcon: Icon = ({ fill = "white" }) => (
<svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill={fill}>
  <path fill-rule="evenodd" d="M38.926 22.24c-7.71-4.579-20.428-5-27.79-2.765a2.235 2.235 0 01-2.788-1.49 2.236 2.236 0 011.491-2.791c8.45-2.566 22.495-2.07 31.371 3.199a2.238 2.238 0 01-2.284 3.847zm-.251 6.783a1.868 1.868 0 01-2.566.614c-6.428-3.95-16.23-5.097-23.836-2.788a1.865 1.865 0 01-2.326-1.242 1.868 1.868 0 011.244-2.326c8.687-2.635 19.486-1.36 26.87 3.18a1.865 1.865 0 01.614 2.562zm-2.929 6.513a1.487 1.487 0 01-2.049.496C28.08 32.6 21.01 31.824 12.684 33.726a1.49 1.49 0 01-.664-2.907c9.111-2.083 16.927-1.186 23.232 2.668.704.428.925 1.347.494 2.049zm-10.969-34.5c-13.21 0-23.92 10.71-23.92 23.92s10.71 23.92 23.92 23.92c13.212 0 23.92-10.71 23.92-23.92S37.99 1.035 24.777 1.035z" clip-rule="evenodd"/>
</svg>
);

export default AnchorIcon;






