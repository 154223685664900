import React from "react";

import { Icon } from '@types';

const AnchorIcon: Icon = ({ fill = "white" }) => (
<svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill={fill}>
  <path d="M13.517.049H3.526A3.478 3.478 0 00.052 3.522v9.991a3.478 3.478 0 003.474 3.474h9.99a3.478 3.478 0 003.474-3.474v-9.99A3.478 3.478 0 0013.517.048zM10.26 11.315l-.307 2.454a.992.992 0 01-.985.87h-.894a.992.992 0 01-.985-.87l-.306-2.454a1.752 1.752 0 011.738-1.97 1.49 1.49 0 01-1.488-1.489c0-.82.667-1.488 1.488-1.488s1.489.667 1.489 1.488a1.49 1.49 0 01-1.489 1.489c1.054 0 1.87.924 1.74 1.97zm-1.739-5.94A2.815 2.815 0 005.71 8.187c0 .559.165 1.08.447 1.518-.203.345-.328.73-.366 1.127a3.791 3.791 0 01-1.073-2.645A3.809 3.809 0 018.52 4.383a3.809 3.809 0 013.805 3.804c0 1.027-.41 1.96-1.073 2.645a2.747 2.747 0 00-.366-1.127c.282-.438.446-.96.446-1.518a2.815 2.815 0 00-2.812-2.812zm2.476 8.045l.153-1.222a4.796 4.796 0 002.168-4.01A4.802 4.802 0 008.521 3.39a4.802 4.802 0 00-4.797 4.797c0 1.675.864 3.152 2.169 4.01l.153 1.223a5.797 5.797 0 01-3.314-5.233 5.796 5.796 0 015.79-5.79 5.796 5.796 0 015.789 5.79 5.797 5.797 0 01-3.314 5.233z"/>
</svg>
);

export default AnchorIcon;






