import React from "react";

import { Icon } from '@types';

const AnchorIcon: Icon = ({ fill = "white" }) => (
<svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill={fill}>
  <g>
    <path d="M6.852 23.633v3.307a3.031 3.031 0 01-6.062 0v-3.307a3.031 3.031 0 016.062 0z"/>
    <path d="M43.225 23.72a3.088 3.088 0 01-.001-.087 3.031 3.031 0 116.06.086h.002v3.307h-.002a3.03 3.03 0 01-6.059 0h-.001v-3.307h.001z"/>
    <path d="M17.323 33.553v3.306a3.03 3.03 0 11-6.062 0v-3.306a3.031 3.031 0 016.062 0zm0-19.84V24.53h-.001a3.031 3.031 0 01-6.06 0h-.001V13.714a3.031 3.031 0 016.062 0z"/>
    <path d="M32.753 17.02a3.03 3.03 0 106.062 0v-3.306a3.031 3.031 0 00-6.062 0z"/>
    <path d="M22.007 7.376a3.031 3.031 0 006.062 0V4.07a3.031 3.031 0 00-6.062 0zm0 35.82a3.031 3.031 0 016.062 0v3.307a3.031 3.031 0 01-6.062 0z"/>
    <path d="M32.753 26.113a3.03 3.03 0 116.062 0v10.746a3.031 3.031 0 01-6.062 0z"/>
    <path d="M28.069 16.47v17.634a3.03 3.03 0 11-6.062 0V16.469a3.03 3.03 0 116.062 0z"/>
  </g>
</svg>
);

export default AnchorIcon;






