import React from "react";

import { Icon } from '@types';

const AnchorIcon: Icon = ({ fill = "white" }) => (
<svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill={fill}>
  <g data-name="anchor fm media social">
    <path d="M19.434 23.133H12.13a4.453 4.453 0 00-3.83-2.227 4.453 4.453 0 000 8.906 4.453 4.453 0 003.83-2.226h7.303a2.226 2.226 0 000-4.453z"/>
    <path d="M25 35a11.132 11.132 0 000-19.281 2.226 2.226 0 00-2.226 3.852 6.68 6.68 0 010 11.577 2.226 2.226 0 00-.802 3.117 2.226 2.226 0 001.915 1.114A2.226 2.226 0 0025 35z"/>
    <path d="M27.07 41.457a17.812 17.812 0 000-32.195 2.23 2.23 0 00-1.914 4.03 13.359 13.359 0 010 24.135 2.226 2.226 0 00.957 4.252 2.405 2.405 0 00.958-.222z"/>
    <path d="M29.23 2.916a2.226 2.226 0 10-1.78 4.075 20.038 20.038 0 010 36.737 2.226 2.226 0 00-1.159 2.939 2.226 2.226 0 002.049 1.336 2.226 2.226 0 00.89-.2 24.491 24.491 0 000-44.887z"/>
  </g>
</svg>
);

export default AnchorIcon;






