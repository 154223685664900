import React from "react";

import { Icon } from '@types';

const TilesIcon: Icon = ({ fill }) => (
<svg 
  height="25"
  xmlns="http://www.w3.org/2000/svg" 
  viewBox="0 0 512  512">
  <path 
    fill={fill} 
    d="M408.43 239.07a15 15 0 00-30 0c0 67.5-54.92 122.43-122.43 122.43s-122.43-54.92-122.43-122.43a15 15 0 00-30 0c0 78.99 60.4 144.13 137.43 151.7v30.98h-15.13c-41.49 0-75.25 33.76-75.25 75.25a15 15 0 0015 15h180.75a15 15 0 0015-15c0-41.5-33.75-75.25-75.25-75.25H271v-30.99c77.03-7.56 137.43-72.7 137.43-151.69z"/>
  <path 
    fill={fill} 
    d="M211.87 150.63a15 15 0 010 30h-46.18v30.25h46.18a15 15 0 010 30h-46.18v.06c0 49.8 40.51 90.31 90.31 90.31s90.31-40.51 90.31-90.31v-.07h-46.18a15 15 0 010-30h46.18v-30.25h-46.18a15 15 0 010-30h46.18v-30.25h-46.18a15 15 0 010-30h46.18v-.06C346.31 40.51 305.8 0 256 0s-90.31 40.51-90.31 90.31v.07h46.18a15 15 0 010 30h-46.18v30.25z"/>
</svg>
);

export default TilesIcon;