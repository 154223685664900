import React from "react";

import { Icon } from '@types';

const AnchorIcon: Icon = ({ fill = "white" }) => (
<svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill={fill} >
  <g>
    <path d="M2.33 7.937v1.128a1.034 1.034 0 01-2.067 0V7.937a1.034 1.034 0 012.067 0z"/>
    <path d="M14.738 7.967a1.053 1.053 0 010-.03 1.034 1.034 0 112.067.03v1.128a1.034 1.034 0 01-2.067 0V7.967z"/>
    <path d="M5.902 11.321v1.128a1.034 1.034 0 11-2.068 0V11.32a1.034 1.034 0 012.068 0zm0-6.767v3.689a1.034 1.034 0 01-2.068 0v-3.69a1.034 1.034 0 012.068 0z"/>
    <path d="M11.166 5.681a1.034 1.034 0 102.067 0V4.554a1.034 1.034 0 00-2.067 0z"/>
    <path d="M7.5 2.392a1.034 1.034 0 002.068 0V1.264a1.034 1.034 0 00-2.068 0zm0 12.219a1.034 1.034 0 012.068 0v1.128a1.034 1.034 0 01-2.068 0z"/>
    <path d="M11.166 8.783a1.034 1.034 0 112.067 0v3.666a1.034 1.034 0 01-2.067 0z"/>
    <path d="M9.568 5.494v6.015a1.034 1.034 0 11-2.068 0V5.494a1.034 1.034 0 112.068 0z"/>
  </g>
</svg>
);

export default AnchorIcon;






