import React from "react";

import { Icon } from '@types';

const AnchorIcon: Icon = ({ fill = "white" }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill={fill}>
    <g data-name="anchor fm media social">
      <path d="M6.997 7.73H4.37a1.602 1.602 0 00-1.378-.801 1.602 1.602 0 000 3.204 1.602 1.602 0 001.378-.8h2.628a.801.801 0 000-1.603z"/>
      <path d="M9 12a4.006 4.006 0 000-6.938.801.801 0 00-.801 1.386 2.404 2.404 0 010 4.166.801.801 0 00-.289 1.122.801.801 0 00.69.4A.801.801 0 009 12z"/>
      <path d="M9.745 14.323a6.41 6.41 0 000-11.585.803.803 0 00-.689 1.45 4.807 4.807 0 010 8.685.801.801 0 00.345 1.53.865.865 0 00.344-.08z"/>
      <path d="M10.522.455a.801.801 0 10-.64 1.466 7.21 7.21 0 010 13.22.801.801 0 00-.417 1.057.801.801 0 00.737.48.801.801 0 00.32-.071 8.813 8.813 0 000-16.152z"/>
    </g>
  </svg>
);

export default AnchorIcon;






