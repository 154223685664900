import React from "react";

import { Icon } from '@types';

const AnchorIcon: Icon = ({ fill = "white" }) => (
<svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill={fill}>
  <path fill-rule="evenodd" d="M13.263 7.69c-2.58-1.532-6.834-1.672-9.297-.925a.748.748 0 11-.434-1.432c2.827-.859 7.525-.693 10.495 1.07a.749.749 0 01-.764 1.287zm-.084 2.27a.625.625 0 01-.858.205C10.17 8.843 6.89 8.46 4.346 9.232a.624.624 0 11-.362-1.194c2.906-.881 6.52-.455 8.99 1.064.293.18.385.565.205.857zm-.98 2.178a.498.498 0 01-.685.166c-1.88-1.148-4.245-1.408-7.03-.771a.499.499 0 01-.223-.973c3.049-.697 5.663-.397 7.773.893.235.143.31.45.165.685zM8.53.596a8.003 8.003 0 10.001 16.006 8.003 8.003 0 000-16.006z" clip-rule="evenodd"/>
</svg>
);

export default AnchorIcon;






