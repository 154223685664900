import React from "react";

import { Icon } from '@types';

const AnchorIcon: Icon = ({ fill = "white" }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill={fill}>
    <path d="M39.28.364H10.412C4.877.364.374 4.867.374 10.401v28.87c0 5.534 4.503 10.037 10.037 10.037h28.87c5.534 0 10.037-4.503 10.037-10.037V10.4c0-5.534-4.503-10.037-10.037-10.037zm-9.41 32.553l-.886 7.092a2.868 2.868 0 01-2.846 2.512h-2.584a2.868 2.868 0 01-2.846-2.512l-.886-7.092a5.063 5.063 0 015.024-5.691 4.307 4.307 0 01-4.302-4.302 4.307 4.307 0 014.302-4.302 4.307 4.307 0 014.302 4.302 4.307 4.307 0 01-4.302 4.302 5.063 5.063 0 015.024 5.691zm-5.024-17.162c-4.48 0-8.126 3.645-8.126 8.125 0 1.615.476 3.12 1.291 4.386a7.938 7.938 0 00-1.058 3.257 10.955 10.955 0 01-3.1-7.643c0-6.062 4.931-10.993 10.993-10.993s10.993 4.931 10.993 10.993c0 2.968-1.182 5.664-3.1 7.643a7.939 7.939 0 00-1.058-3.257 8.076 8.076 0 001.29-4.386c0-4.48-3.645-8.125-8.125-8.125zM32 39l.441-3.531c3.771-2.48 6.266-6.748 6.266-11.589 0-7.643-6.218-13.861-13.861-13.861-7.643 0-13.861 6.218-13.861 13.861 0 4.84 2.495 9.11 6.266 11.589L17.69 39c-5.654-2.687-9.574-8.454-9.574-15.12 0-9.224 7.505-16.729 16.729-16.729s16.729 7.505 16.729 16.73c0 6.665-3.92 12.432-9.575 15.119z"/>
  </svg>
);

export default AnchorIcon;






